import Component from '../core/Component';
import scrollToElement from '../services/Viewport/scrollToElement'
import { getScrollTop } from '../services/Viewport/scroll'
import EventBus from '../core/EventBus'

export default class ScrollAnchors extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            sections: []
        }

        this.min = 0
        this.max = Infinity
        this.active = false
    }

    prepare() {
        this.sections = this.ref.sections.map(element => ({
            element,
            id: element.id,
            active: false
        }))
        this.resize()
        window.addEventListener('scroll', this.handleScroll)
        // this.render()
    }

    destroy() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        this.render()
    }

    resize() {
        const offset = getScrollTop()
        const windowHeight = window.innerHeight
        const threshold = 0.4 * windowHeight

        this.sections = this.sections
            .map(item => {
                const box = item.element.getBoundingClientRect()

                item.min = offset - threshold + box.top
                item.max = offset + box.top + box.height - threshold

                return item
            })

        this.min = Math.min(...this.sections.map(section => section.min))
        this.max = Math.max(...this.sections.map(section => section.max))
    }

    render() {
        const y = getScrollTop()

        this.sections.forEach((section, index) => {
            if (section.min < y && y < section.max && !section.active) {
                section.active = true
                EventBus.emit('section:enter', {
                    first: index === 0,
                    last: index === this.sections.length - 1,
                    id: section.id
                })
            }

            if ((section.min > y || y > section.max) && section.active) {
                section.active = false
                EventBus.emit('section:leave', {
                    first: index === 0,
                    last: index === this.sections.length - 1,
                    id: section.id
                })
            }
        })

        if (y > this.max && this.active) {
            this.active = false
            EventBus.emit('section:leaveAll', {
                exit: 'bottom'
            })
        }

        if (y < this.min && this.active) {
            this.active = false
            EventBus.emit('section:leaveAll', {
                exit: 'top'
            })
        }

        if (this.min < y && y < this.max && !this.active) {
            this.active = true
        }
    }
}



