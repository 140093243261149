import Component from '../core/Component'
import scrollToElement from '../services/Viewport/scrollToElement'
import EventBus from '../core/EventBus'
import { toggleClass } from '../utils/dom'

const STATES = {
    ACTIVE: 'is-active'
}

export default class SidebarNav extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            links: []
        }
    }

    prepare() {
        this.ref.links.forEach(element => element.addEventListener('click', this.handleClick))
        this.links = this.ref.links
            .reduce((acc, element) => {
                const id = element.href.split('#')[1]
                acc[id] = element
                return acc
            }, {})

        EventBus.on('section:enter', this.handleSectionEnter)
        EventBus.on('section:leaveAll', this.handleSectionLeaveAll)
    }

    destroy() {
        EventBus.off('section:enter', this.handleSectionEnter)
        EventBus.off('section:leaveAll', this.handleSectionLeaveAll)
    }

    handleSectionEnter = ({ id }) => {
        if (id in this.links) {
            Object.keys(this.links).forEach(key => toggleClass(this.links[key], STATES.ACTIVE, key === id))
        }
    }

    handleSectionLeaveAll = ({ exit }) => {
        this.ref.links.forEach(element => element.classList.remove(STATES.ACTIVE))
    }


    handleClick = event => {
        const id = event.target.href.split('#')[1]

        if (!id) {
            return
        }

        const target = document.getElementById(id)

        if (!target) {
            return
        }

        event.preventDefault()

        scrollToElement(target)
    }
}