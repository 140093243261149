import Component from '../core/Component';
import PerfectScrollbar from 'perfect-scrollbar';


export default class CustomScrollbar extends Component {
    constructor(element) {
        super(element)
    }

    prepare() {
        const ps = new PerfectScrollbar(this.element, {
            useBothWheelAxes: false,
            suppressScrollX: true
        });
    }
}